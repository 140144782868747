/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { getLoginUser } from './loginService';

const base_url = `${process.env.BACKEND_API_BASE_URL}/stripe.php`;

export const stripeService = {
  create_strip_account: async () =>
    axios.get(base_url, {
      params: {
        method: 'create_strip_account_PergoPay',
        token: getLoginUser().token,
      },
    }),
  load_strip_account: async () =>
    axios.get(base_url, {
      params: {
        method: 'load_strip_account',
        token: getLoginUser().token,
      },
    }),
  get_account_information: async () =>
    axios.get(base_url, {
      params: {
        method: 'get_account_information',
        token: getLoginUser().token,
      },
    }),
  get_transaction_history: async () =>
    axios.get(base_url, {
      params: {
        method: 'get_transaction_history',
        token: getLoginUser().token,
      },
    }),
  refund_transaction: async (id) =>
    axios.get(base_url, {
      params: {
        method: 'refund_transaction',
        id,
        token: getLoginUser().token,
      },
    }),
};
